import React from "react";
import styled from "styled-components/macro";
import { QUERIES } from "../../constants";
import SearchAndFilter from "./SearchAndFilter";

const Hero = ({ onSearch, onCategoryFilter, onTypeFilter }) => {
  const categories = [
    "Finance Basics",
    "Budgeting",
    "Taxes",
    "Saving",
    "Investing",
    "Real Estate",
  ];

  const types = ["Books", "Videos", "Websites", "Articles", "Podcasts"];

  return (
    <Wrapper>
      <Content>
        <Title>A collection of useful personal finance resources</Title>
        <SearchAndFilter
          categories={categories}
          types={types}
          onCategoryChange={onCategoryFilter}
          onTypeChange={onTypeFilter}
          onSearch={onSearch}
        />
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  // border-bottom: 1px solid var(--border);
  // max-width: var(--maxWidth);
  margin: 0 auto;
  padding: 10px 25px;
  // border-top: 1px solid #aaa;
  @media ${QUERIES.tabletAndUp} {
    padding: 0 40px;
  }
`;

const Content = styled.div`
  // background: var(--background);
  // box-shadow: var(--boxShadow);
  border-radius: var(--borderRadius);
  padding: 25px 0px 0px;
  text-align: center;
  @media ${QUERIES.desktopAndUp} {
    padding: 40px 0px 20px 0;
  }
`;

const Title = styled.h1`
  font-size: 1.45rem;
  color: var(--heading);
  margin-bottom: 0px;
  max-width: var(--maxWidth);
  margin: 0 auto;
  text-align: center;
  font-weight: normal;
  @media ${QUERIES.desktopAndUp} {
    font-size: 1.95rem;
    padding: 0 40px;
  }
`;

// const Subtitle = styled.p`
//   font-size: 1.25rem;
//   color: var(--text);
//   margin-bottom: 32px;
// `;

export default Hero;
