import React from "react";
import styled from "styled-components/macro";
import { COLORS, QUERIES, WEIGHTS } from "../../constants";

const ListingItemCard = ({
  to,
  title,
  description,
  category,
  pricing,
  website,
  type,
  children,
}) => {
  if (children) {
    return { children };
  }
  return (
    <>
      <Card href={website} target="_blank">
        <TitleContainer>
          <LogoContainer>
            {website && (
              <img
                src={`https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${website}&size=128`}
                alt={"logo"}
              />
            )}
          </LogoContainer>

          <ContentContainer>
            <Title>{title}</Title>
            <CompanyName>{description}</CompanyName>
            <Subtitle>
              <span style={{ display: "flex", gap: "8px" }}>
                <SubtitleSpan>{category}</SubtitleSpan>
                <SubtitleTypeSpan>{type}</SubtitleTypeSpan>
              </span>
              <span>
                <span
                  style={{
                    color: "#f2f2f2",
                    textTransform: "capitalize",
                  }}
                >
                  {pricing}
                </span>
              </span>
            </Subtitle>
          </ContentContainer>
        </TitleContainer>
      </Card>
    </>
  );
};

export default ListingItemCard;

// const getEmojiByType = (type) => {
//   switch (type) {
//     case "book":
//       return "📕";
//     case "video":
//       return "📹";
//     case "website":
//       return "💻";
//     case "app":
//       return "📱";
//     case "image":
//       return "🖼️";
//     default:
//       return "";
//   }
// };

const TitleContainer = styled.div`
  display: flex;
  // flex-direction: column;
  justify-content: left;
  align-content: left;
  align-items: flex-start;
  @media ${QUERIES.tabletAndUp} {
    flex-direction: row;
  }
`;

const ContentContainer = styled.div`
  width: 100%;
`;

const Title = styled.h2`
  color: var(--heading);
  font-size: 1.2rem;
  line-height: 1.45;
  text-transform: capitalize;
  margin-bottom: 6px;
  @media ${QUERIES.tabletAndUp} {
    font-size: 1.25rem;
  }
`;

const Card = styled.a`
  width: 100%;
  // border-radius: var(--borderRadius);
  // background-color: var(--background);
  border-bottom: 1px solid rgb(54, 63, 77);
  padding: 6px 0 28px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  text-decoration: none;
  box-shadow: --var(--boxShadow);
  // border-bottom: 1px solid #ddd;
  // border-right: 1px solid #ddd;
  // border-left: 1px solid #ddd;

  @media ${QUERIES.tabletAndUp} {
    padding: 6px 0 28px;
  }

  &:hover ${Title} {
    // color: ${COLORS.gray[300]};
    text-decoration: underline;
    -webkit-text-decoration-color: hsl(235, 69%, 61%);
    text-decoration-color: hsl(235, 69%, 61%);
    -webkit-text-decoration-style: wavy;
    text-decoration-style: wavy;
    tetx-decoration-thickness: 2px;
  }

  &:focus {
    outline-color: ${COLORS.violet[200]};
    outline-offset: 4px;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  margin-right: 18px;
  margin-top: 3px;
  margin-bottom: 15px;
  @media ${QUERIES.tabletAndUp} {
    margin-bottom: 0;
  }
  & > img {
    width: 40px;
    height: 40px;
    max-width: 40px;
    // border: 1px solid rgb(132, 139, 151);
    border-radius: 50%;
    padding: 4px;
    background-color: rgb(215, 215, 215);
  }
`;

// const PostedAtAndContractContainer = styled.p`
//   display: flex;
//   gap: 12px;
//   color: var(--text);
//   font-size: 1rem;
//   transition: all 200ms ease-in;
// `;

const CompanyName = styled.span`
  color: var(--text);
  font-size: 1rem;
  transition: all 200ms ease-in;

  @media ${QUERIES.tabletAndUp} {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    /* Needed to make it work */
    overflow: hidden;
    display: -webkit-box;
  }
`;

const Subtitle = styled.h3`
  font-size: ${14 / 16 + "rem"};
  color: ${COLORS.gray[300]};
  margin-top: 14px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-weight: ${WEIGHTS.normal};
`;

const SubtitleSpan = styled.span`
  padding: 1px 8px;
  border-radius: var(--borderRadius);
  background-color: ${COLORS.gray[50]};
  color: #f2f2f2;
`;

const SubtitleTypeSpan = styled.span`
  color: #f2f2f2;
  padding: 1px 8px;
  border-radius: var(--borderRadius);
  background-color: ${COLORS.gray[50]};
`;
