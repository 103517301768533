import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";

import { COLORS, QUERIES } from "../../constants";
import useListings from "../../hooks/useListings";

import Button from "@mui/joy/Button";
import { useStoreState } from "easy-peasy";
import Container from "../Container";
import Header from "../Header";
import Hero from "../Header/Hero";
import ListingItemCard from "../ListingItemCard/ListingItemCard";
import Loader from "../Loader/Loader";
const DEFAULT_ITEMS_PER_PAGE = 8;
// const SIGNUP_FORM_INTERVAL = 4;

const LoadMoreListingsButton = ({
  filteredDataLength,
  loadMore,
  setLoadMore,
  setFiltering,
}) => {
  const [loading, setLoading] = useState(false);
  const handleClick = async () => {
    setLoading(true);
    await delay(Math.random() * 400 + 250);
    setLoadMore(loadMore + 9);
    setLoading(false);
  };

  if (filteredDataLength > loadMore) {
    return (
      <Button
        sx={(theme) => ({
          color: `${COLORS.white}`,
          width: "100%",
          border: `1px solid ${COLORS.violet[300]}`,
          background: `${COLORS.violet[300]}`,
          borderRadius: "var(--borderRadius)",
          "&:hover": {
            border: `2px solid ${COLORS.violet[200]}`,
            background: `${COLORS.violet[400]}`,
            color: `${COLORS.white}`,
          },
          // px: 6,
        })}
        aria-label="Loading..."
        size="lg"
        data-test="load-more-btn"
        onClick={handleClick}
        loading={loading}
        variant="outlined"
        loadingPosition="start"
      >
        Load more
      </Button>
    );
  } else if (filteredDataLength === 0) {
    return <P>No listings found. Try changing the filters.</P>;
  } else {
    return <P>That's all folks!</P>;
  }
};

const P = styled.p`
  color: ${COLORS.gray[200]};
`;

const Divider = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  margin-top: 20px;
  @media ${QUERIES.desktopAndUp} {
    margin-top: 0;
    margin-bottom: 20px;
  }

  &::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid ${COLORS.gray[300]};
    margin-left: 20px;
  }
`;

const DividerLabel = styled.span`
  color: ${COLORS.gray[200]};
  font-size: 1rem;
  white-space: nowrap;
  font-weight: bold;
`;

const FilterLabels = ({
  selectedCategory,
  selectedType,
  filteredDataLength,
}) => {
  return (
    <>
      {!selectedCategory && !selectedType ? (
        `All Resources (${filteredDataLength || 0})`
      ) : (
        <>
          {selectedCategory ? selectedCategory : "All Categories"} -{" "}
          {selectedType ? selectedType : "All Types"} ({filteredDataLength || 0}
          )
        </>
      )}
    </>
  );
};

const Listings = () => {
  const [loadMoreCount, setLoadMoreCount] = React.useState(
    DEFAULT_ITEMS_PER_PAGE
  );
  const { status, data, error } = useListings();
  const [filtering, setFiltering] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [filterState, setFilterState] = useState({
    pricing: "",
    category: "",
    type: "",
  });
  const [filterReset, setFilterReset] = useState(false);
  const selectedTag = useStoreState((state) => state.selectedTag);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedType, setSelectedType] = useState("");

  useEffect(() => {
    if (filterReset) {
      setFilterState({ pricing: "", category: "", type: "" });
      setFilterReset(false);
    }
  }, [filterReset]);

  useEffect(() => {
    const filterListings = async () => {
      setFiltering(true);
      const filteredResults = await filterData(
        { selectedTag },
        data,
        searchQuery,
        selectedCategory,
        selectedType
      );
      setFilteredData(filteredResults);
      setFiltering(false);
    };

    filterListings();
  }, [data, selectedTag, searchQuery, selectedCategory, selectedType]);

  const renderListings = () => {
    if (status === "loading" || filtering) {
      return (
        <LoadingContainer>
          <Loader />
        </LoadingContainer>
      );
    }

    if (status === "error") {
      return <span>Error: {error.message}</span>;
    }

    return (filteredData || data)
      ?.slice(0, loadMoreCount)
      .map((listingItem) => (
        <ListingItemCard
          key={listingItem.id}
          to={`/listingItem/${listingItem.id}`}
          {...listingItem}
        />
      ));
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
    setLoadMoreCount(DEFAULT_ITEMS_PER_PAGE);
  };

  const handleCategoryFilter = (category) => {
    setSelectedCategory(category);
    setLoadMoreCount(DEFAULT_ITEMS_PER_PAGE);
  };

  const handleTypeFilter = (type) => {
    setSelectedType(type);
    setLoadMoreCount(DEFAULT_ITEMS_PER_PAGE);
  };

  return (
    <>
      <Header />
      <Hero
        onSearch={handleSearch}
        onCategoryFilter={handleCategoryFilter}
        onTypeFilter={handleTypeFilter}
      />
      <Main>
        <Container>
          <Divider>
            <DividerLabel>
              <FilterLabels
                selectedCategory={selectedCategory}
                selectedType={selectedType}
                filteredDataLength={filteredData?.length}
              />
            </DividerLabel>
          </Divider>

          <Wrapper data-test="listing-items-list">{renderListings()}</Wrapper>

          {/* {status !== "loading" && !filtering && <SignupForm />} */}

          <ButtonContainer>
            <LoadMoreListingsButton
              filteredDataLength={filteredData?.length}
              loadMore={loadMoreCount}
              setLoadMore={setLoadMoreCount}
              setFiltering={setFiltering}
            />
          </ButtonContainer>
        </Container>
      </Main>
    </>
  );
};

export default Listings;

const Main = styled.main``;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 0 20px 0;
  gap: 10px;

  @media ${QUERIES.tabletAndUp} {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: minmax(30px, auto);
    gap: 20px;
    margin-top: 10px;
  }

  @media ${QUERIES.desktopAndUp} {
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: minmax(30px, auto);
  }
`;

const LoadingContainer = styled.div`
  display: grid;
  place-items: center;
`;

const ButtonContainer = styled.div`
  text-align: center;
  padding-bottom: 7%;
  margin: 30px auto;
  @media ${QUERIES.desktopAndUp} {
    padding-bottom: 3%;
    width: 30%;
  }
`;

// const getSignUpFormPosition = (loadMore, interval = SIGNUP_FORM_INTERVAL) => {
//   const position = Math.floor((loadMore - 1) / interval) * interval + interval;
//   return position > loadMore ? loadMore : position;
// };

const filterData = async (filterBy, data, search, category, type) => {
  if (!data) return [];

  await delay(Math.random() * 400 + 250);
  let filteredData = [...data];

  if (search) {
    filteredData = filteredData.filter(
      (item) =>
        item.title.toLowerCase().includes(search.toLowerCase()) ||
        item.description.toLowerCase().includes(search.toLowerCase())
    );
  }

  if (category) {
    filteredData = filteredData.filter(
      (item) => item.category.toLowerCase() === category.toLowerCase()
    );
  }

  if (type) {
    filteredData = filteredData.filter(
      (item) => item.type.toLowerCase() === type.toLowerCase()
    );
  }

  const filters = {
    pricing: (item) =>
      item.pricing
        ?.toLowerCase()
        ?.includes(filterBy.pricing.trim().toLowerCase()),
    category: (item) =>
      item.category
        ?.toLowerCase()
        ?.includes(filterBy.category.trim().toLowerCase()),
    type: (item) =>
      item.type?.toLowerCase()?.includes(filterBy.type.trim().toLowerCase()),
    selectedTag: (item) =>
      filterBy.selectedTag.trim().toLowerCase() === "all" ||
      item.category
        ?.toLowerCase()
        ?.includes(filterBy.selectedTag.trim().toLowerCase()),
  };

  Object.entries(filterBy).forEach(([key, value]) => {
    if (value?.trim() && filters[key]) {
      filteredData = filteredData.filter(filters[key]);
    }
  });

  return filteredData;
};

// function getRandomNumber(min, max) {
//   return Math.floor(Math.random() * (max - min + 1)) + min;
// }

function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
